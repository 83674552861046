.App {
  font-family: Lato, "Helvetica Neue", Helvetica, Roboto, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 18px;
  font-weight: 300;
  padding-top: 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.json-area {
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: 100%;
  height: 90vh;
}

.frs-large-button {
  width: 100%;
  background-color: rgba(56, 214, 139, 1);
  color: #FFF !important;
  border-radius: 3px;
  line-height: 20px;
  padding: 8px;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.formio-component-radio {
  width: 100%;
  margin-bottom: 0rem !important;
}

.editor {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  color: #424242;
  box-sizing: border-box;
}

.formio-component-htmlelement{
  width: 100%;
  margin-bottom: 0rem !important;
  box-shadow: 0 1.2px 6px -2px rgba(0,0,0,0.6);
  padding: 22px 20px;
  font-family: Lato, "Helvetica Neue", Helvetica, Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  border: none;
}

.formio-component-radio {
  box-shadow: 0 1.2px 6px -2px rgba(0,0,0,0.6);
  padding: 22px 20px;
  font-family: Lato, "Helvetica Neue", Helvetica, Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

.formio-component-radio>.form-radio>.form-check-inline>label>span {
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  text-transform: uppercase;
  font-family: inherit;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: #333;
  position: relative;
  border: 0;
  text-align: center;
  border-radius: 3px;
  -webkit-font-smoothing: subpixel-antialiased;
  box-shadow: 0 2px 6px -2px rgba(0,0,0,0.5);
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 0.1s;
  outline: 0;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.formio-component-radio>.form-radio>.form-check-inline>label>input {
  display: none;
}

.formio-component-radio>.form-radio>.form-check-inline {
  width: 50%;
}

.formio-component-radio>.form-radio>.form-check-inline.radio-selected>label>span {
  background-color: rgba(56, 214, 139, 1);
  color: #FFF !important;
}

.formio-component-radio>.form-radio>.form-check-inline.radio-selected>label>span:hover {
  background-color: rgba(56, 214, 139, 1);
  color: #333;
}

.formio-component-radio>.form-radio>.form-check-inline:not(.radio-selected)>label>span:hover {
  box-shadow: 0 4px 14px -6px rgba(0,0,0,0.8);
  background-color: #f8f8f8;
}

.formio-component-radio>.form-radio>.form-check-inline {
  margin-right: 0rem;
}

.formio-component-radio>.form-radio>.form-check-inline>.form-check-label {
  width: 100%
}

.formio-component-radio>.form-radio {
   width: 100%;
   display: flex;
}

.editor>div>.alert{
  display: none;
}

.formio-error-wrapper {
  color: inherit !important;
  background-color: inherit !important;
  border-color: inherit !important;
}

.temp-link {
  color: #4495d4;
  text-decoration:underline;
  cursor: pointer;
}