.nav-stacked>li{
    width: 100%;
}

.modal-lg, .modal-xl {
    max-width: 90vw;
}

.navbar>.form-group {
    margin-bottom: 0px;
}


.formio-component-allowCalculateOverride,
.formio-component-calculateServer,
.formio-component-calculateValuePanel,
.formio-component-customDefaultValuePanel,
.formio-component-clearOnHide,
.formio-component-redrawOn,
.formio-component-encrypted,
.formio-component-protected,
.formio-component-dbIndex,
.formio-component-json-validation-json,
.formio-component-simple-conditional,
.formio-component-logic,
.formio-component-custom-validation-js,
.formio-component-tags,
.formio-component-properties,
.formio-component-attributes,
.formio-component-attributes+div {
    visibility: hidden;  
}

.formio-component-radio.formio-component-persistent {
    visibility: hidden;
}

.formio-component .card-header-tabs li:nth-last-child(-n+3) {
    visibility: hidden;
}