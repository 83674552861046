
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700|Material+Icons");
body {
  margin: 0;
  font-family: Lato, "Helvetica Neue", Helvetica, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
